(function($) {
    $(() => {

        $('.js-tooltip-trigger').click(function() {
            $(this).toggleClass('is-shown');
        });

        $('.js-tooltip-trigger--shown').click(function() {
            $(this).find('.custom-tooltip').remove();
        });

        $(document).on("click", function(event){
            if(!$(event.target).closest(".js-tooltip-trigger").length){
                $('.js-tooltip-trigger').removeClass('is-shown');
            }
        });

    });
})(jQuery);
