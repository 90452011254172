(function($) {
    $(() => {

        $('.js-faq-trigger').click(function() {
            $(this).parents('.faq__item').toggleClass('is-opened');

            if ($(this).parents('.faq__item').hasClass('is-opened')) {
                $(this).parents('.faq__item').find('.faq__content').show();
            } else {
                $(this).parents('.faq__item').find('.faq__content').hide();
            }
        });

    });
})(jQuery);
