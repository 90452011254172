(function($) {
    $(() => {

        $('.js-qanda-trigger').click(function() {
            $(this).parents('.qanda__item').toggleClass('is-opened');

            if ($(this).parents('.qanda__item').hasClass('is-opened')) {
                $(this).parents('.qanda__item').find('.qanda__content').show();
            } else {
                $(this).parents('.qanda__item').find('.qanda__content').hide();
            }
        });

    });
})(jQuery);
