(function($) {
    $(() => {

        $('.js-accordion-trigger').click(function() {
            $(this).toggleClass('is-opened');
            $(this).next('.js-accordion-content').slideToggle(200);
        });

    });
})(jQuery);
