(function($) {
    $(() => {
        
        $('.navbar-toggler').on('click', function() {
            $(this).toggleClass('is-active');
            $('.navbar-collapse').toggleClass('is-opened');
        });
        
        $('.dropdown-toggle').on('click', function() {
            $(this).parents('.navbar-nav').toggleClass('is-opened');
        });

        $(document).on("click", function(event){
            if($(event.target).closest(".dropdown-item").length) {
                $('.navbar-collapse').removeClass('is-opened');
            } else if(!$(event.target).closest(".dropdown-toggle").length) {
                $('.navbar-nav').removeClass('is-opened');
            }
        });
    
    });
})(jQuery);
