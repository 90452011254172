(function($) {
    $(() => {

        $('.article .slider').slick({
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            prevArrow: '.article .arrow--left',
            nextArrow: '.article .arrow--right',
            arrows: true,
            dots: false,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  arrows: true,
                  dots: false
                }
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: false,
                  dots: true
                }
              }
            ]
        });

        $('.modal .slider').slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '.modal .arrow--left',
            nextArrow: '.modal .arrow--right',
            arrows: true,
            dots: false,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  arrows: true,
                  dots: false
                }
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: false,
                  dots: true
                }
              }
            ]
        });         

        $('.slider .slide').click(function() {
            const seq = $(this).data('seq');
            $('#galleryModal').modal('show');
            $('.modal .slider').slick('setPosition');
            $('.modal .slider').slick('slickGoTo', seq);
    
            $(document).keydown(function(e){
              switch (e.which){
              case 37:    //left arrow key
                  $('.modal .slider').slick('slickPrev');
                  break;
                case 39:    //right arrow key
                  $('.modal .slider').slick('slickNext');
                  break;
              }
            });
        });

        $('.rating-slider').slick({
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          prevArrow: '.rating-slider__arrows .arrow--left',
          nextArrow: '.rating-slider__arrows .arrow--right',
          arrows: true,
          dots: false,
          responsive: [
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false
              }
            }
          ]
      });         

      $('.partner-slider').slick({
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        prevArrow: '.partner-slider__arrows .arrow--left',
        nextArrow: '.partner-slider__arrows .arrow--right',
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              arrows: false,
              dots: true
            }
          }
        ]
      });         

      $('.steps-slider').slick({
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: false,
        dots: false,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false,
              dots: true
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: true
            }
          }
        ]
      });         

    });
})(jQuery);
