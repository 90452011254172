(function($) {
    $(() => {

        $.datepicker.setDefaults($.datepicker.regional['hu']);

        $('.datepicker').datepicker({
            dateFormat: 'yy. MM dd.',
            localization: 'hu',
            beforeShow: function (input, inst) {
                setTimeout(function () {
                    inst.dpDiv.css({
                        top: $(".datepicker").offset().top + 63
                    });
                }, 0);
            }            
        });

        $('.datepicker-year').datepicker( {
            changeMonth: true,
            changeYear: true,
            showButtonPanel: true,
            dateFormat: 'MM yy',
            onClose: function(dateText, inst) { 
                var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
                var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
                $(this).datepicker('setDate', new Date(year, month, 1));
            }
        });
    

    });
})(jQuery);