(function($) {
    $(() => {

        $('.js-sidebar-trigger').click(function() {
            $(this).parents('.sidebar__header').toggleClass('is-opened');
            $(this).parents('.sidebar__block').find('.sidebar__content ul').slideToggle(200);
        });

    });
})(jQuery);
