(function($) {
    $(() => {

        $('.js-scroll-trigger').click(function(){ 
            $('html,body').animate({ scrollTop: 0 }, 'slow');
            return false; 
        });

        $(window).scroll(function() {            
            if ($(window).scrollTop() >= ($(window).height() / 2)) {
                $('.scroll-top').addClass('is-shown');
            } else {
                $('.scroll-top').removeClass('is-shown');
            }

            if (($(window).scrollTop() + $(window).height()) > $('footer').offset().top) {
                let transform = $('footer').offset().top - ($(window).scrollTop() + $(window).height());
                $('.scroll-top').css({'transform' : `translate(-50%, ${transform}px)`});
            }
        });
        
    });
})(jQuery);
